import styled from "styled-components";
import headerLogo from "../../assets/images/Logo.png";
import { colors } from "../../common/styles/variables";
import { mobileRange, minTablet, tabletRange, minLaptop, minLaptopL, minIPadPro, ipadProRange} from "../../common/styles/breakpoints";
import searchIcon from "../../assets/images/searchIcon.png";
import searchIconActive from "../../assets/images/searchIconActive.png";
import Menu from "../../assets/images/Menu.png";
import MenuMobile from "../../assets/images/menu-icon.svg";
import Close from "../../assets/images/Close.png";

export const Header = styled.div`
    border-top: 0.5em solid ${colors.header_border};
    border-bottom: ${props => props.isSearch ? "none" : `1px solid ${colors.mercury}`};
    background-color: ${colors.white};
    z-index: ${props => props.changeZIndex ? "unset" : "99"};
    width: 100%;
    top: 0;
    position: ${props => props.changeZIndex ? "absolute" : "fixed"};
    .container {
        max-width: 1160px;
        padding: 0;
    }

    @media ${mobileRange} {
      border-bottom: 1px solid ${colors.mercury};
    }

    .navbar {
        color: ${colors.header_border};
        background: transparent;
        padding: 0;
        .active-nav {
          color: ${colors.bondi_blue};
        }
        .search-logo {
          display: none;
          @media ${mobileRange} {
            display: inline-block;
            margin-right: 8px;
          }
        }
        .phactmi_logo {
          display: flex;
          align-items: center;
          margin-top: -10px;
          cursor: pointer;
          @media ${mobileRange} {
            flex-direction: column;
            align-items: unset;
            margin-top: -5px;
            padding-left: 10px;
          }
          img {
            width: 110px;
            @media ${mobileRange} {
              width: 90px;
            }
          }
          .phactmi_txt {
            padding-left: 10px;
            margin-left: 10px;
            border-left: 1px solid ${colors.solid__light_grey};
            max-width: 185px;
            text-align: left;
            font-size: 11px;
            @media ${mobileRange} {
              font-size: 8px;
              margin-left: 14px;
              max-width: 135px;
              border: none;
            }
          }
        }
        a {
          padding: 12px 10px;
          color: ${colors.nav_blue};
          font-size: 18px;
          line-height: 1.3;
          letter-spacing: -0.26px;
          text-align: left;
          text-decoration: none;

          :hover {
            color: ${colors.bondi_blue};
          }

          &.login-header {
            color: ${colors.bondi_blue};
            border: 1px solid ${colors.bondi_blue};
            border-radius: 3px;
            margin: 0 0  0 20px;
            padding: 5px 10px 5px 10px;
            height: fit-content;
            @media ${mobileRange} {
              font-size: 18px;
              margin: 0;
              padding: 0 10px;
            }
            @media ${tabletRange} {
              margin: 0;
            }
            @media ${ipadProRange} {
              margin: 5px 0  0 10px;
            }
            &.active-nav {
              color: ${colors.bondi_blue};
              border: 1px solid ${colors.bondi_blue};
            }
          }

          &.logout-header {
            color: ${colors.nav_blue};
            border: 1px solid ${colors.nav_blue};
            border-radius: 3px;
            margin: 5px 0 0 10px;
            padding: 5px 10px 5px 10px;
            height: fit-content;
            @media ${tabletRange} {
              margin: 0;
            }
            @media ${ipadProRange} {
              margin: 5px 0  0 10px;
            }
          }
          &.member-tour {
            z-index: 101;
            background: ${colors.white};
            pointer-events: none;
          }

          &.search-header {
            background: no-repeat right;
            background-size: 14.8px 14.8px;
            padding-right: 25px;

            div {
              @media ${mobileRange} 
              {
                display: inline-block !important; // !important is used here to override the display:flex property added by bootstrap d-flex class.
                background: url(${props => props.isSearch ? searchIconActive : searchIcon }) no-repeat right;
                padding-right: 15px;
                background-size: 15px 15px;
                padding-right: 20px;
              }
            }
            @media ${mobileRange} {
              padding-right: 8px;
            }

            @media ${minTablet} {
              background-image: url(${props => props.isSearch ? searchIconActive : searchIcon });
              background-size: 10px 10px;
              padding-right: 20px;
            }

            @media ${minLaptop} {
              background-image: url(${props => props.isSearch ? searchIconActive : searchIcon });
              background-size: 15px 15px;
              padding-right: 25px;
            }
          }

          @media ${mobileRange} {
            background-color: ${colors.white};
            font-size: 20px;
            line-height: 2.46;
            text-align: center;
            padding: 12px;
            &:active {
              background-color: rgb(0,181,154, 0.2);
              color: ${colors.nav_blue};
            }
          }
          
          @media ${minTablet} {
            background-color: transparent;
          }

          @media ${tabletRange} {
            font-size: 13px;
          }

        }

        @media ${mobileRange} {
          margin: 10px 0;
        }

        @media ${minTablet} {
          margin: 15px 40px 0;
          padding-bottom: 15px;
        }

        @media ${minLaptop} {
          margin: 15px 0 0;
          padding: 0 30px 15px;
        }
        
        @media ${ipadProRange} {
          margin: 15px 30px 0 30px;
          padding-bottom: 15px;
        }

        @media ${minLaptopL} {
          margin: 15px 0 0;
          padding: 0 10px 15px;
        }
        @media ${minIPadPro} {
          margin: 15px 0 0;
          padding: 0 10px 15px;
        }
        @media ${tabletRange} {
          font-size: 13px;
          line-height: 1.3;
          text-align: center;
        }

        .navbar-toggler{
          width: 38.7px;
          height: 38.7px;
          padding: 0;
          margin-right: 6px;
          outline: none;
          background-color: ${colors.bondi_blue};

          @media ${mobileRange} {
            background-color: transparent;
            width: 24px;
            height: 16px
          }
          &.collapsed {
            .navbar-toggler-icon {
              background-image: url(${Close});
              background-size: 100% 100%;
              width: 24px;
              height: 24px;
            }
          }
          .navbar-toggler-icon {
            background-image: url(${Menu}); 
            background-size: 100% 100%;
            width: 100%;
            height: 100%;

            @media ${mobileRange} {
              background-image: url(${MenuMobile});
            }
          }
        }
        
        .navbar-brand {
          background-image: url(${headerLogo});
          background-size: contain;
          background-repeat: no-repeat;
          width: 160px;
          height: 46px;

          @media ${mobileRange} {
            margin-left: 20px;
            width: 112px;
            height: 26px;
          }

          @media ${tabletRange} {
            width: 100px;
            height: 30px;
          }
        }

        .navbar-nav {
          align-items: center;
          @media ${mobileRange} {
            margin-top: 21px;
            padding-bottom: 42px;
            max-height: ${props => props.maxheight + "px"}};
          }
        }
    }
`;

export const HeaderLabel = styled.div`
      display: initial;
      font-size: 13px;
      line-height: 1.18;
      color: ${colors.bondi_blue};
      margin-right: 5px;
      @media ${mobileRange} {
        display: none;
      }
  `;

  export const PortalLink = styled.div`
      padding: 8px 16px;
      border: 1px solid #00B59A;
      margin-left: 16px
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        margin: 0;
        cursor: pointer;
      }
      img[alt="open-dropdown"] {
        width: 8px;
        cursor: pointer;
        margin-left: 10px;
      }
      img[alt="member-portal-link"] {
        margin-right: 8px;
        width: 20px;
      }
  `;

export const PortalDropdown = styled.ul`
      position: absolute;
      left: 16px;
      bottom: -90px;
      margin: 0;
      z-index: 99;
      background: white;
      width: 92%;
      border-radius: 4px;
      box-shadow: 0 0 10px #003D4A40;
      display: flex;
      gap: 12px;
      flex-direction: column;
      padding: 12px 0;
       li {
        text-decoration: none;
        list-style: none;
        padding: 3px 16px 0;
        cursor: pointer
        :hover {
          background-color: #F5F9FA
        }
        img {
          margin-right: 8px;
          width: 20px;
        }
      }
  `;
